<template>
  <div :class="containerClassList" :name="name">
    <input
      :id="id"
      :ref="id"
      :class="inputClassList"
      :type="inputType.FILE"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :name="name"
      @change="handleFileChange"
      accept="application/pdf, image/jpeg, image/png, image/gif"
      style="display: none;"
      v-model="hiddenInputValue"
    />

    <div v-if="innerValue" class="csn-file-field-file-container">
      <span
        class="csn-file-field-file-name"
        data-toggle="tooltip"
        data-placement="bottom"
        :title="fileName"
      >
        {{ fileName }}
      </span>
      <button
        class="casino-btn csn-file-field-reset-button"
        @click="handleResetButtonClick"
      >
        <CloseIcon />
      </button>
    </div>
    <button v-else :class="buttonClassList" @click="handleButtonClick">
      <ButtonLoader v-if="isLoading" />
      <span v-else>{{ label || t('choose_file') }}</span>
    </button>

    <Balloon :text="message" :arrowPosition="balloonArrowPosition" />
  </div>
</template>

<script>
import {
  FILE_FIELD,
  BalloonArrowPosition,
  INPUT_CONTAINER_CLASS,
  EMPTY_STRING,
  Digit,
} from '@/constants'
import { inputMixin } from '@/mixins'

const CSN_FILE_FIELD = 'csn-file-field'
const BUTTON_CLASS_LIST =
  'casino-btn casino-btn-default btn csn-file-field-button'
const FORM_CONTROL = 'form-control'

export default {
  name: FILE_FIELD,
  inheritAttrs: false,
  components: {
    Balloon: () => import('@/components/Balloon'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
  },
  mixins: [inputMixin],
  data: function () {
    return {
      fileName: null,
      isLoading: false,
      hiddenInputValue: EMPTY_STRING,
    }
  },
  props: {
    readonly: Boolean,
    buttonClass: String,
  },
  computed: {
    containerClassList() {
      return [INPUT_CONTAINER_CLASS, CSN_FILE_FIELD, this.containerClass]
    },
    buttonClassList() {
      return [BUTTON_CLASS_LIST, this.buttonClass]
    },
    inputClassList() {
      return [FORM_CONTROL, this.inputClass]
    },
    balloonArrowPosition: () => BalloonArrowPosition.TOP_LEFT,
    t() {
      return this.$createComponentTranslator(FILE_FIELD)
    },
  },
  methods: {
    handleButtonClick() {
      this.makeReady()
      this.$refs[this.id].click()
    },
    handleFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const file = files[Digit.NOUGHT]
      const fileName = file.name
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = () => {
        this.fileName = fileName
        this.handleInput({
          fileName,
          file: reader.result.split(',')[Digit.ONE],
        })
      }
      reader.onerror = (error) => {
        console.log('Error: ', error)
      }
    },
    handleResetButtonClick() {
      this.fileName = null
      this.hiddenInputValue = EMPTY_STRING
      this.handleInput(EMPTY_STRING)
    },
  },
}
</script>
